import React, {useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import _, {isNull} from 'lodash'
import {
    nextFriday,
    nextSunday,
    endOfDay,
    startOfDay
} from 'date-fns'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {PlayIcon, StopIcon} from '@heroicons/react/24/outline'

import {toApiDateFormat, toApiTimeFormat} from '../../../../utils'
import {columns, isCompletedOptions} from './constants'

import {AddTaskModal} from '../../../../components/AddTaskModal'
import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'

import './TasksList.scss'
import {useQueryParams} from 'use-query-params'
import {TableListFilter} from './filters'
import {useQueryResourceList} from '../../../../queries/rest'
import {keepPreviousData} from '@tanstack/react-query'
import {FilterForm} from '../../../../components/FilterForm'
import {StackDatatable} from '../../../../components/StackDatatable'
import {StackTaskCard} from './partials'
import FilterHeader from '../../../../components/Datatable/Filters/FilterHeader/FilterHeader'
import {ToolTipWrapper} from '../../../../components/ToolTipWrapper'
import {stopRunningTaskTimeLog} from '../../../../redux/taskTimeLogs'
import {ResourceFilter} from '../../../../components/FilterForm/filters'
import {useMutationResourceUpdate} from '../../../../mutations/rest'

export const TasksList = ({
    entityProfileID,
    selectedEntityID,
    anyModalIsOpen,
    createTimeLog,
    stopTaskTimeLog,
    currentTimeLog
}) => {
    const [query, setQuery] = useQueryParams(TableListFilter.mapping)
    const [useEffectApplied, setUseEffectApplied] = useState(false)
    useEffect(() => {
        if (!query.owner_id) {
            setQuery({...query, owner_id: {value: entityProfileID, label: 'Sarcinile mele'}})
        }
        setQuery({...query, is_completed: isCompletedOptions.FALSE})
        setUseEffectApplied(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const today = new Date()
    const tomorrow = new Date().setDate(today.getDate() + 1)
    const nextMonday = new Date().setDate(today.getDate() + (8 - today.getDay()))

    const {data: todayTasks, refetch: refetchTasks} = useQueryResourceList(
        RESOURCES.tasks,
        {
            entity_id: selectedEntityID,
            start__lte: toApiDateFormat(tomorrow),
            ...TableListFilter.getAPIFilters(query)
        },
        {
            placeholderData: keepPreviousData,
            enabled: !anyModalIsOpen && useEffectApplied
        }
    )

    const updateTaskMutation = useMutationResourceUpdate(RESOURCES.tasks)

    const handleChangeCompletion = async (task) => {
        let taskData = {completion: null}

        if (isNull(task.completion)) {
            const datetime = `${toApiDateFormat(new Date())}T${toApiTimeFormat(new Date())}`

            taskData = {completion: datetime}
        }

        // updateTask(taskData, task.id)
        await updateTaskMutation.mutateAsync({...taskData, id: task.id})
        await refetchTasks()
    }

    const handleStartTimer = useCallback((task) => {
        createTimeLog({
            entity_id: selectedEntityID,
            task_id: task.id,
            date: toApiDateFormat(new Date()),
            start: toApiTimeFormat(new Date())
        })
    }, [createTimeLog, selectedEntityID])

    const handleStopTimer = useCallback((hasAutoStopped = false) => {
        stopTaskTimeLog(hasAutoStopped)
    }, [stopTaskTimeLog])

    return (
        <div className="page-info">
            <div className="page-info-extended">
                <div className="page-filters-container">
                    <div className="filters-header">
                        <p className="table-title">Listă sarcini</p>
                        <div className="w-52">
                            <ResourceFilter
                                value={query.owner_id}
                                filterName={'owner_id'}
                                filterConfig={TableListFilter.config.owner_id}
                                setFieldValue={(filterName, e) => {
                                    setQuery({...query, owner_id: e})
                                }}
                                selectedEntityID={selectedEntityID}
                                labelAccessorFunction={
                                    (item) => item.id === entityProfileID?
                                        'Sarcinile mele': item[TableListFilter.config.owner_id.labelAccessor]
                                }
                            />
                        </div>
                    </div>
                    <FilterHeader
                        filterable
                        appliedFilters={TableListFilter.getUIFilters(query)}
                        filtersForm={() => <FilterForm filterClass={TableListFilter}/>}
                        handleRemoveFilter={(filter) => {
                            setQuery({
                                ...query,
                                [filter.key]: null
                            })
                        }}
                        searchable
                        searchPlaceholder="Caută sarcini"
                        searchDefaultValue={query.search}
                        onSearch={(event) => setQuery({
                            ...query,
                            search: event.target.value
                        })}
                    />
                </div>
                <Datatable
                    title="Astăzi"
                    data={todayTasks?.data?.results || []}
                    columns={[
                        ...columns,
                        {
                            Header: 'Ore Lucrate',
                            accessor: 'duration_as_hours',
                            Cell: ({value, row: {original: task}}) => <>
                                {value || 0} {
                                    _.isEmpty(currentTimeLog) || !_.isNil(currentTimeLog.stop) ? (
                                        <Button
                                            size='small'
                                            icon={() => (
                                                <ToolTipWrapper text='Începe pontaj'>
                                                    <PlayIcon className="w-4" onClick={() => handleStartTimer(task)}/>
                                                </ToolTipWrapper>
                                            )}
                                        />
                                    ) : (
                                        currentTimeLog.task_id === task.id ? (
                                            <Button
                                                size='small'
                                                icon={() => (
                                                    <ToolTipWrapper text='Opreste timer'>
                                                        <StopIcon
                                                            className="w-4"
                                                            onClick={() => handleStopTimer(false)}
                                                        />
                                                    </ToolTipWrapper>
                                                )}
                                            />
                                        ) : (
                                            <Button
                                                size='small'
                                                disabled={true}
                                                icon={() => (
                                                    <ToolTipWrapper text='Timerul este deja pornit pentru alta sarcina'>
                                                        <PlayIcon className="w-4"/>
                                                    </ToolTipWrapper>
                                                )}
                                            />
                                        )
                                    )
                                }
                            </>,
                            style: {
                                whiteSpace: 'nowrap',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                                justifyContent: 'space-between'
                            }
                        },
                        {
                            Header: '',
                            accessor: 'id',
                            Cell: ({value: taskID, row: {original: task}}) => (
                                <div className="tasks-list-datatable-action-buttons-column">
                                    <Link to={`/tasks/${taskID}`}>
                                        <Button title="Vizualizare" variant="text" size="small"/>
                                    </Link>
                                    <Button
                                        title={isNull(task.completion) ? 'Finalizează' : 'Activează'}
                                        color={isNull(task.completion) ? 'secondary' : 'gray'}
                                        onClick={async () => {
                                            await handleChangeCompletion(task)
                                        }}
                                        variant="text"
                                        size="small"
                                    />
                                </div>
                            )
                        }
                    ]}
                    nextPage={todayTasks?.data?.pages.next}
                    previousPage={todayTasks?.data?.pages.previous}
                    currentPage={todayTasks?.data?.page}
                    totalPages={todayTasks?.data?.number_of_pages}
                    pageSize={todayTasks?.data?.page_size}
                    onChangePage={(page) => {
                        setQuery({
                            ...query,
                            page: page
                        })
                    }}
                    onChangePageSize={(pageSize) => {
                        setQuery({
                            ...query,
                            page_size: pageSize
                        })
                    }}
                />
                <StackDatatable sectionsConfig={[
                    ...([5, 6, 0].includes(today.getDay()) ? [
                        {
                            sectionTitle: 'Următoarea săptămână',
                            resource: RESOURCES.tasks,
                            resourceFilters: {
                                is_planned: true,
                                start__gte: toApiDateFormat(startOfDay(tomorrow)),
                                start__lte: toApiDateFormat(endOfDay(nextFriday(today)))
                            },
                            useQueryOverrides: {
                                enabled: !anyModalIsOpen && useEffectApplied
                            },
                            render: (item) => <StackTaskCard task={item}/>,
                            filterConfig: TableListFilter
                        },
                        {
                            sectionTitle: 'In viitor',
                            resource: RESOURCES.tasks,
                            resourceFilters: {
                                is_planned: true,
                                start__gte: toApiDateFormat(nextMonday)
                            },
                            useQueryOverrides: {
                                enabled: !anyModalIsOpen && useEffectApplied
                            },
                            render: (item) => <StackTaskCard task={item}/>,
                            filterConfig: TableListFilter
                        }
                    ] : [
                        {
                            sectionTitle: 'Restul săptămânii',
                            resource: RESOURCES.tasks,
                            resourceFilters: {
                                is_planned: true,
                                start__gte: toApiDateFormat(startOfDay(tomorrow)),
                                start__lte: toApiDateFormat(endOfDay(nextSunday(today)))
                            },
                            useQueryOverrides: {
                                enabled: !anyModalIsOpen && useEffectApplied
                            },
                            render: (item) => <StackTaskCard task={item}/>,
                            filterConfig: TableListFilter
                        },
                        {
                            sectionTitle: 'In viitor',
                            resource: RESOURCES.tasks,
                            resourceFilters: {
                                is_planned: true,
                                start__gte: toApiDateFormat(nextMonday)
                            },
                            useQueryOverrides: {
                                enabled: !anyModalIsOpen && useEffectApplied
                            },
                            render: (item) => <StackTaskCard task={item}/>,
                            filterConfig: TableListFilter
                        }
                    ]),
                    {
                        sectionTitle: 'Neplanificate',
                        resource: RESOURCES.tasks,
                        resourceFilters: {
                            is_planned: false
                        },
                        useQueryOverrides: {
                            enabled: !anyModalIsOpen && useEffectApplied
                        },
                        render: (item) => <StackTaskCard task={item}/>,
                        filterConfig: TableListFilter
                    }
                ]}/>
            </div>

            <AddTaskModal/>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        anyModalIsOpen: !!state.modals.type,
        entityProfileID: state.localConfigs.entityProfileID,
        selectedEntityID: state.localConfigs.selectedEntityID,
        currentTimeLog: state.taskTimeLogs.currentTaskTimeLog
    }
}

const mapDispatchToProps = (dispatch) => ({
    createTimeLog: (data) => dispatch(RESOURCES.taskTimeLogs.create(data)),
    stopTaskTimeLog: (hasAutoStopped = false) => dispatch(stopRunningTaskTimeLog(hasAutoStopped))
})

export default connect(mapStateToProps, mapDispatchToProps)(TasksList)
