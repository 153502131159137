import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {TrashIcon} from '@heroicons/react/24/outline'
import {PlusIcon} from '@heroicons/react/24/solid'
import {useFormik} from 'formik'

import {lightFormat} from 'date-fns'
import {values as _values, filter, head, isEmpty, isNil, isNull, round, get, map, includes} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {invoicesSchema} from '../../assets/validations'

import {getFieldOptions} from '../../utils'
import {RON_CURRENCY_VALUE, ronCurrency} from '../../utils/constants'
import {taxPercentageOptions, unitOptions} from '../../utils/types'

import {Button} from '../Button'
import {Datatable} from '../Datatable'
import {Input} from '../Input'
import {Select} from '../Select'

import {useExchangeRateAndTotalLogic} from './hooks/useExchangeRateAndTotalLogic'
import {EditInvoiceEntryModal} from './partials'

import './InvoiceEntriesSection.scss'

export const InvoiceEntriesSection = ({
    entries,
    entriesErrors,
    invoiceContract,
    handleAddEntry,
    handleEditEntry,
    handleRemoveEntry,
    invoicesOptions,
    exchangeRates,
    invoiceCurrency,
    invoiceExchangeRate,
    isUsingVat
}) => {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [selectedEntry, setSelectedEntry] = useState(undefined)
    const [selectedEntryIdx, setSelectedEntryIdx] = useState(undefined)
    const [validationErrors, setValidationErrors] = useState([])

    const {handleChange, setFieldValue, handleBlur, handleSubmit, handleReset, values, errors, touched} = useFormik({
        initialValues: {
            name: '',
            unit: unitOptions.BUC,
            quantity: 1,
            currency: null,
            tax: taxPercentageOptions[isUsingVat ? 19 : 0],
            taxValue: '',
            taxValueRON: '',
            price: '',
            priceRON: '',
            exchangeRate: '',
            total: '',
            totalRON: ''
        },
        validationSchema: invoicesSchema.entries,
        onSubmit: (values) => {
            handleAddEntry(values)
            handleReset()
        }
    })

    const entriesWithErrors = useMemo(
        () => {

            return map(entries, (entry, index) => {
                const errors = (validationErrors && validationErrors[index]) || {}
                // TODO: We need to mutate in order to keep the deletion functionality working.
                //  We should find a better way to do this.
                entry.errors = errors

                return entry
            })
        },
        [validationErrors, entries]
    )

    useEffect(
        () => {
            setValidationErrors([])
        },
        [entries, setValidationErrors]
    )

    useEffect(
        () => {
            setValidationErrors(entriesErrors)
        },
        [entriesErrors, setValidationErrors]
    )

    const isInvoiceCurrencyRON = useMemo(() => {
        return invoiceCurrency?.value === RON_CURRENCY_VALUE
    }, [invoiceCurrency?.value])

    const handleOpenEditEntryModal = useCallback((entry, entryIdx) => {
        setIsEditModalOpen(true)
        setSelectedEntry(entry)
        setSelectedEntryIdx(entryIdx)
    }, [])

    const invoiceEntriesColumns = useMemo(() => {
        return [
            {
                Header: 'Nr. crt.',
                accessor: '_idx',
                Cell: ({row: {index}}) => index + 1
            },
            {
                Header: 'Denumire produs / serviciu',
                accessor: 'name'
            },
            {
                Header: 'U.M.',
                accessor: 'unit',
                Cell: ({value: unit}) => unit ? unit.label : '-'
            },
            {
                Header: 'Cantitate',
                accessor: 'quantity'
            },
            {
                Header: `Preț unitar (${invoiceCurrency.label})`,
                accessor: 'price',
                Cell: ({value: price, row: {original: entry}}) => {
                    if(isInvoiceCurrencyRON) {
                        return `${round(entry.priceRON, 4)} ${invoiceCurrency.label}`
                    }

                    return `${price} ${entry.currency.label}`
                }

            },
            {
                Header: `Valoare (${invoiceCurrency.label})`,
                accessor: '_value',
                Cell: ({row: {original: entry}}) => {
                    if(isInvoiceCurrencyRON) {
                        return `${round(entry.quantity * entry.priceRON, 2)} ${invoiceCurrency.label}`
                    }

                    return `${entry.quantity * entry.price} ${entry.currency.label}`
                }
            },
            ...(isUsingVat ? [{
                Header: 'TVA',
                accessor: 'tax',
                Cell: ({value: tax, row: {original: entry}}) => {
                    const totalKey = isInvoiceCurrencyRON ? 'totalRON' : 'total'

                    return `${round(tax.value / 100 * entry[totalKey], 2)} ${invoiceCurrency.label}`
                }
            }] : []),
            {
                Header: 'Acțiuni',
                accessor: 'id',
                Cell: ({row: {original: entry, index}}) => (
                    <div className='datatable-row-buttons-container'>
                        <Button
                            title='Editează'
                            onClick={() => handleOpenEditEntryModal(entry, index)}
                            size='small'
                        />
                        <Button
                            icon={() => <TrashIcon />}
                            onClick={() => handleRemoveEntry(entry)}
                            size='small'
                            color='red'
                        />
                    </div>
                )
            }
        ]
    }, [handleOpenEditEntryModal, handleRemoveEntry, invoiceCurrency.label, isInvoiceCurrencyRON, isUsingVat])

    const isEntryCurrencyRON = useMemo(() => {
        return values.currency?.value === RON_CURRENCY_VALUE
    }, [values.currency?.value])

    const [currencies, setCurrencies] = useState(undefined)

    useEffect(() => {
        let currenciesToSet = undefined
        const allCurrencies = getFieldOptions(invoicesOptions, 'currency')

        if(isNil(invoiceCurrency)) {
            currenciesToSet = allCurrencies
        } else {
            // FOR NOW: If the invoice currency is not 'RON' we only support entries in that currency
            if(invoiceCurrency.value !== RON_CURRENCY_VALUE) {
                currenciesToSet = [invoiceCurrency]
                setFieldValue('currency', invoiceCurrency)
            } else {
                // If the invoice currency is 'RON' the entries can only have the currency of 'RON' and one other so
                // that the calculation of the price with the exchange rate is easier
                const entriesWithOtherCurrency = filter(
                    entries,
                    (entry) => entry.currency?.value !== RON_CURRENCY_VALUE
                )

                if(!isEmpty(entriesWithOtherCurrency)) {
                    const otherCurrency = head(entriesWithOtherCurrency).currency

                    currenciesToSet = filter(allCurrencies, (currency) => {
                        return currency.value === RON_CURRENCY_VALUE || currency.value === otherCurrency.value
                    })
                } else {
                    currenciesToSet = allCurrencies
                }

                setFieldValue('currency', ronCurrency)
            }
        }

        setCurrencies(currenciesToSet)
    }, [entries, invoiceCurrency, invoicesOptions, setFieldValue])

    const priceInputLabel = useMemo(() => {
        const initialLabel = isUsingVat ? 'Preț fără TVA' : 'Preț'

        if(!isNull(values.currency) && !isEntryCurrencyRON) {
            return `${initialLabel} (${values.currency.value})*`
        }

        return initialLabel
    }, [isUsingVat, isEntryCurrencyRON, values.currency])

    useExchangeRateAndTotalLogic(
        isInvoiceCurrencyRON,
        isEntryCurrencyRON,
        invoiceExchangeRate,
        exchangeRates,
        values,
        setFieldValue
    )

    const autocompleteEntryNameOptions = useMemo(() => {
        return [
            {
                label: 'Număr intern contract',
                value:  invoiceContract?.internal_series_number || ''
            },
            {
                label: 'Serie barou contract',
                value: invoiceContract?.bar_series_name || ''
            },
            {
                label: 'Număr serie barou contract',
                value: invoiceContract?.bar_series_number || ''
            },
            {
                label: 'Dată contract',
                value: invoiceContract ? lightFormat(new Date(invoiceContract?.created), 'dd/MM/yyyy') : ''
            }
        ]
    }, [invoiceContract])

    const handleClickAutocompleteNamePill = useCallback((value) => {
        if(!isNil(invoiceContract)) setFieldValue('name', `${values.name} ${value}`)
    }, [invoiceContract, setFieldValue, values.name])

    const handleCloseEditEntryModal = useCallback(() => {
        setIsEditModalOpen(false)
        setSelectedEntry(undefined)
        setSelectedEntryIdx(undefined)
    }, [])

    const editEntryAndResetModal =useCallback((entry, entryIdx) => {
        handleEditEntry(entry, entryIdx)
        handleCloseEditEntryModal()
    }, [handleCloseEditEntryModal, handleEditEntry])

    return (
        <div className="avo-invoice-add-products-section">
            <form className='add-invoice-entry-form-container'>
                <div className="autocomplete-invoice-entry-name-section">
                    <p>Completează câmpul denumire cu:</p>
                    {autocompleteEntryNameOptions.map(({label, value}, idx) => (
                        <Button
                            title={label}
                            disabled={isNil(invoiceContract)}
                            onClick={() => handleClickAutocompleteNamePill(value)}
                            key={label}
                            size='small'
                        />
                    ))}
                </div>
                <div className="add-invoice-entry-row">
                    <Input
                        label='Denumire*'
                        value={values.name}
                        onChange={handleChange('name')}
                        name='name'
                        onBlur={handleBlur('name')}
                        frontendErrors={errors}
                        touched={touched.name}
                        fullWidth
                    />
                    <Select
                        label='U.M.*'
                        value={values.unit}
                        options={_values(unitOptions)}
                        onChange={(value) => setFieldValue('unit', value)}
                        name='unit'
                        onBlur={handleBlur('unit')}
                        frontendErrors={errors}
                        touched={touched.unit}
                        fullWidth
                    />
                    <Input
                        label='Cantitate*'
                        value={values.quantity}
                        onChange={handleChange('quantity')}
                        name='quantity'
                        onBlur={handleBlur('quantity')}
                        frontendErrors={errors}
                        touched={touched.quantity}
                        fullWidth
                    />
                    <Select
                        label='Moneda*'
                        value={values.currency}
                        options={currencies}
                        onChange={(e) => setFieldValue('currency', e)}
                        name='currency'
                        onBlur={handleBlur('currency')}
                        frontendErrors={errors}
                        touched={touched.currency}
                        fullWidth
                    />
                    {isUsingVat && <Select
                        label='Cotă TVA*'
                        value={values.tax}
                        options={_values(taxPercentageOptions)}
                        onChange={(e) => setFieldValue('tax', e)}
                        name='tax'
                        onBlur={handleBlur('tax')}
                        frontendErrors={errors}
                        touched={touched.tax}
                        fullWidth
                    />}
                    <Input
                        label={priceInputLabel}
                        value={values.price}
                        onChange={handleChange('price')}
                        name='price'
                        onBlur={handleBlur('price')}
                        frontendErrors={errors}
                        touched={touched.price}
                        type='number'
                        fullWidth
                    />
                    <div className="invoice-product-form-small-button-container">
                        <Button
                            type='button'
                            icon={() => <PlusIcon />}
                            onClick={handleSubmit}
                            fullWidth
                        />
                    </div>
                </div>
                {!isNil(values.currency?.value) && !isEntryCurrencyRON && isInvoiceCurrencyRON && (
                    <div className="add-invoice-entry-row exchange-rate-container">
                        <Input
                            label='Curs*'
                            value={values.exchangeRate}
                            onChange={handleChange('exchangeRate')}
                            name='exchangeRate'
                            onBlur={handleBlur('exchangeRate')}
                            frontendErrors={errors}
                            touched={touched.exchangeRate}
                            disabled={!isInvoiceCurrencyRON}
                            type='number'
                            fullWidth
                        />
                        <Input
                            label='Preț fără TVA (RON)'
                            value={values.priceRON}
                            name='priceRON'
                            type='number'
                            disabled
                            fullWidth
                        />
                    </div>
                )}
                <div className="invoice-product-form-large-button-container">
                    <Button
                        type='button'
                        icon={() => <PlusIcon />}
                        onClick={handleSubmit}
                        fullWidth
                    />
                </div>
            </form>
            <Datatable
                hideTableHead
                data={entriesWithErrors}
                columns={invoiceEntriesColumns}
                rowProps={row => {
                    const nonFieldErrors = get(row, ['original', 'errors', 'non_field_errors'], [])
                    const errorCodes = map(nonFieldErrors, 'code')
                    const hasStornoErrors = (
                        includes(errorCodes, 'product_already_storned') ||
                        includes(errorCodes, 'product_is_storno') ||
                        includes(errorCodes, 'entry_total_different_from_entries_sum')
                    )

                    return {
                        className: hasStornoErrors ? 'strikeout-row' : null
                    }
                }}
            />
            <EditInvoiceEntryModal
                open={isEditModalOpen}
                onClose={handleCloseEditEntryModal}
                entry={selectedEntry}
                entryIdx={selectedEntryIdx}
                handleEditEntry={editEntryAndResetModal}
                autocompleteEntryNameOptions={autocompleteEntryNameOptions}
                invoiceContract={invoiceContract}
                unitOptions={unitOptions}
                taxPercentageOptions={taxPercentageOptions}
                currencies={currencies}
                priceInputLabel={priceInputLabel}
                isInvoiceCurrencyRON={isInvoiceCurrencyRON}
                invoiceExchangeRate={invoiceExchangeRate}
                exchangeRates={exchangeRates}
                isUsingVat={isUsingVat}
            />
        </div>
    )}

const mapStateToProps = (state) => ({
    invoicesOptions: state.invoices.options,
    exchangeRates: state.exchangeRate.data
})

const mapDispatchToProps = (dispatch) => ({
    getInvoicesOptions: () => dispatch(RESOURCES.invoices.getOptions())
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceEntriesSection)
